import { React, useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { roleOptions, roleOptionsAdmin } from './roleOptions';
import { AppContext } from '../../util/AppContext';
import { getConfigValue } from "../../util/getConfigValue";

function EditUserDropdown({ setFormRole, setStatus }) {
    const { state } = useLocation();
    const [user] = useState(state.user);
    const [appState] = useContext(AppContext);
    const [userRole] = useState(appState.role);

    const statusOptions = [
        { value: '2', label: (getConfigValue(appState?.config?.UserManagement?.AddEditUser?.UserRole?.Options?.Enabled, appState)?.Value || null) },
        { value: '3', label: (getConfigValue(appState?.config?.UserManagement?.AddEditUser?.UserRole?.Options?.Disabled, appState)?.Value || null) },
    ];

    function handleStatus(event) {
        setStatus(event.target.value);
    };

    useEffect(() => {
        setFormRole(user.role);
    }, []);

    return (
        <>
            <h2 className="type-20 type-bold mb-3">{getConfigValue(appState?.config?.UserManagement?.AddEditUser?.DropDown?.Title, appState)?.Value || null}</h2>
            <label
                htmlFor="user-status"
                className="form-label">
                {getConfigValue(appState?.config?.UserManagement?.AddEditUser?.Status?.Label, appState)?.Value || null}
            </label>
            <select
                className="form-select"
                aria-label="Select this user's status"
                id="user-status"
                onChange={handleStatus}
                disabled={user.source === "Application" ? false : true}
                defaultValue={user.status === 'Disabled' ? 3 : 2}>
                {statusOptions.map((item, index) => {
                    return (
                        <option key={index} value={item.value} >{item.label}</option>
                    )
                })}
            </select>
            <div className="my-3"></div>
            <label
                htmlFor="user-role"
                className="form-label">
                {getConfigValue(appState?.config?.UserManagement?.AddEditUser?.DropDown?.Role?.Label, appState)?.Value || null}
            </label>
            <select
                className="form-select"
                aria-label="Select this user's role"
                id="user-role"
                defaultValue={user.role}
                onChange={(e) => {
                    setFormRole(e.target.value)
                }}>
                <option hidden value="">{getConfigValue(appState?.config?.UserManagement?.AddEditUser?.UserRole?.Options, appState)?.Value || null}</option>
                {userRole === "Precision" ?
                    roleOptions.map((item, index) => {
                        return (
                            <option key={index} value={item.value} label={item.label}>{item.label}</option>
                        )
                    }) :
                    roleOptionsAdmin.map((item, index) => {
                        return (
                            <option key={index} value={item.value} label={item.label}>{item.label}</option>
                        )
                    })
                }
            </select>
            {user.source === "DataLoader" ? <div className="type-12 color-grey-d1 mt-1">{getConfigValue(appState?.config?.UserManagement?.AddEditUser?.UserRole?.Messages?.DataLoader, appState)?.Value || null}</div> : ""}
            <div className="my-6"></div>
        </>
    );
}
export default EditUserDropdown;