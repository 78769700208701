import React from 'react';

function AddressAlert({ alert }) {
    const { heading, message, type } = alert;

    // for types/colors, search: scss-docs-start theme-colors-map

    if (!alert) {
        console.error('AlertDismissible: alert is null');
        return null;
    }

    if (!heading || !message || !type) {
        console.error('AlertDismissible: alert is missing heading, message, or type');
        return null;
    }

    return (
        <div className={`alert alert-${type}`} role="alert">
            <div className="alert-icon"></div>
            <div className="alert-content">
                <h4 className="h5 alert-heading">{heading}</h4>
                <p>{message}</p>
            </div>
        </div>
    );
}

export default AddressAlert;