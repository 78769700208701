import UserModal from './UserModal';
import React, { useState, useContext, useEffect } from 'react';
import { roleOptions } from '../userDropdowns/roleOptions';
import { AppContext } from '../../util/AppContext';
import { setUserState } from '../../API/userMangement';
import Dropdown from 'react-bootstrap/Dropdown';
import { getConfigValue } from '../../util/getConfigValue';
import { useNavigate } from 'react-router-dom';
import { userActions } from '../userComponents/userActions';
import { useErrorBoundary } from 'react-error-boundary';

function UserInfoRow({ user, setAlert, refreshUsers }) {
	const [showUserModal, setShowUserModal] = useState(false);
	const [action, setAction] = useState();
	const [appState] = useContext(AppContext);
	const [userStatus, setUserStatus] = useState();
	const { showBoundary } = useErrorBoundary();

	useEffect(() => {
		setUserStatus(user.status !== 'Disabled' ? 'Enabled' : 'Disabled');
	}, [user]);

	const navigate = useNavigate();

	function handleImpersonateClick() {
		setAction(userActions.impersonate);
		setShowUserModal(true);
	}

	function navigateToEdit(user) {
		navigate('/EditUserPage', { state: { user: user } });
	}

	async function handleEnableDisable(user) {
		try {
			if (userStatus !== 'Disabled') {
				setAction(userActions.disable);
				setShowUserModal(true);
			} else {
				const response = await setUserState(user.eMail, 'Provisioned');

				if (response.status !== 200) {
					throw new Error(response.status);
				} else {
					setUserStatus('Enabled');
					refreshUsers();
				}
			}
		} catch (e) {
			showBoundary(e);
		}
	}

	return (
		<div>
			<div className='row pd-plan-table-row pd-plan-table-card align-items-center py-3 py-lg-4 py-xl-5'>
				<div className='col-10 col-md-7 col-lg-4'>
					<div>
						{user.firstName} {user.lastName}
					</div>
					<div className='color-grey-d1'>{user.eMail}</div>
				</div>
				<div className='col-md-2 d-none d-lg-block'>{roleOptions.find((x) => x.value === user.role).label}</div>
				<div className='col-md-2 d-none d-md-block d-lg-block'>
					<span className='fa-regular fa-check color-success me-2'></span>
					<span className='d-none d-lg-inline'>{userStatus}</span>
				</div>
				<div className='col-md-3 d-none d-lg-block'>
					<ul className='d-flex list-unstyled my-0'>
						<li className='me-4 me-xl-5'>
							{user.source === 'DataLoader' ? (
								<span className='fa-regular fa-signal-stream me-2 color-success'></span>
							) : (
								<span className='fa-regular fa-signal-stream color-grey-l3 me-2'></span>
							)}
							Feed
						</li>
						<li className='me-4 me-xl-5'>
							{user.source === 'Application' ? (
								<span className='fa-regular fa-user-xmark me-2 color-success'></span>
							) : (
								<span className='fa-regular fa-user-xmark color-grey-l3 me-2'></span>
							)}
							Application
						</li>
					</ul>
				</div>
				<div className='col-2 col-md-3 col-lg-1 d-flex justify-content-end'>
					<Dropdown>
						<Dropdown.Toggle className='btn btn-ghost-secondary btn-sm btn-icon-only'>
							<span className='fa-regular fa-ellipsis btn-icon'></span>
						</Dropdown.Toggle>
						<Dropdown.Menu>
							<Dropdown.Item onClick={() => navigateToEdit(user)} className='dropdown-item cursor' role='button'>
								{getConfigValue(appState?.config?.UserInfo?.Row?.ViewOrEdit, appState)?.Value || null}
							</Dropdown.Item>

							{appState.role === 'Precision' && !appState.impersonate.isActive && (
								<>
									<hr className='dropdown-divider' />
									<Dropdown.Item onClick={() => handleImpersonateClick()} className='dropdown-item cursor' role='button'>
										{getConfigValue(appState?.config?.UserInfo?.Row?.Impersonate, appState)?.Value || null}
									</Dropdown.Item>
								</>
							)}
							{user.source === 'Application' && (
								<>
									<hr className='dropdown-divider' />
									<Dropdown.Item onClick={() => handleEnableDisable(user)} className='dropdown-item cursor' role='button'>
										{userStatus !== 'Disabled'
											? getConfigValue(appState?.config?.UserInfo?.Row?.DisableUser, appState)?.Value
											: getConfigValue(appState?.config?.UserInfo?.Row?.EnableUser, appState)?.Value}
									</Dropdown.Item>
								</>
							)}
						</Dropdown.Menu>
					</Dropdown>
				</div>
			</div>
			<UserModal show={showUserModal} setShow={setShowUserModal} user={user} action={action} setAlert={setAlert} onUpdateUserStatus={refreshUsers} />
		</div>
	);
}
export default UserInfoRow;
