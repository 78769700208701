import Modal from 'react-bootstrap/Modal';
import React, { useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import AdobePreview from './AdobePreview';
import { getConfigValue } from '../util/getConfigValue';
import { AppContext } from '../util/AppContext';
import { trackDocumentRequest, trackingEventType } from '../API/documentGenerate';
import { useErrorBoundary } from 'react-error-boundary';

function PreviewModal({ show, setShow, onGenerateClick, isValid, isLoading, pdfUrl, showGenerateBack, showPrintDownload, pdfFileName }) {
	const [appState] = useContext(AppContext);
	const { showBoundary } = useErrorBoundary();
	const canDownload = getConfigValue(appState.config?.Generate?.Document?.Download?.Enabled, appState)?.Value === 'true' || false;
	const canPrint = getConfigValue(appState.config?.Generate?.Document?.Print?.Enabled, appState)?.Value === 'true' || false;
	const canSendToVendor = getConfigValue(appState?.config?.ProfessionalPrinting?.Enabled, appState)?.Value === 'true' || false;

	const navigate = useNavigate();

	useEffect(() => {
		const previewFile = async (url) => {
			// track preview request
			trackDocumentRequest(trackingEventType.Preview, appState.lastGeneratedDocument, appState.sellSheetId);

			const response = await fetch(url);
			const blob = await response.blob();
			const viewAdobePreview = new AdobePreview();
			await viewAdobePreview.ready();
			var reader = new FileReader();
			reader.onloadend = function (e) {
				var filePromise = Promise.resolve(e.target.result);
				// Pass the filePromise and name of the file to the previewFile API
				viewAdobePreview.previewFileUsingFilePromise('pdf-div', filePromise, pdfFileName);
			};
			reader.readAsArrayBuffer(blob);
		};

		if (pdfUrl) {
			try {
				previewFile(pdfUrl);
			} catch (e) {
				showBoundary(e);
			}
		}
	}, [pdfUrl]);

	function onPrintClick(event) {
		event.preventDefault();
		if (pdfUrl) {
			try {
				// track print request
				trackDocumentRequest(trackingEventType.Print, appState.lastGeneratedDocument, appState.sellSheetId);

				window.open(pdfUrl, 'PRINT');
			} catch (e) {
				showBoundary(e);
			}
		}
	}

	function onDownloadClick() {
		if (pdfUrl) {
			try {
				// track download request
				trackDocumentRequest(trackingEventType.DownLoad, appState.lastGeneratedDocument, appState.sellSheetId);

				const link = document.createElement('a');
				link.href = pdfUrl;
				link.download = pdfFileName;
				link.click();
			} catch (e) {
				showBoundary(e);
			}
		}
	}

	function onCreateProfessionalPrintingDocument() {
		navigate('/createProfessionalPrintingDocument', { state: { pdfURL: pdfUrl, fileName: pdfFileName } });
	}

	return (
		<Modal show={show} onHide={() => setShow(false)} size='xl' scrollable>
			<Modal.Header className='primary' closeButton>
				<Modal.Title className='type-18'>{getConfigValue(appState.config.Preview?.Header, appState)?.Value}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{showGenerateBack && (
					<div className='row gx-3 my-2 mb-3 justify-content-end'>
						<div className='col-6 col-lg-4'>
							<button onClick={() => setShow(false)} className='btn btn-secondary btn-icon mt-2 w-100'>
								{getConfigValue(appState.config.Preview?.Back, appState)?.Value}
							</button>
						</div>
						<div className='col-6 col-lg-4'>
							<button
								disabled={!isValid || isLoading}
								onClick={onGenerateClick}
								className={`btn btn-success mt-2 w-100 ${isValid || isLoading ? '' : 'disabled'} ${isLoading ? "btn-working" : ""}`}>
								{' ' + getConfigValue(appState.config.Preview?.Generate, appState)?.Value}
							</button>
						</div>
					</div>
				)}
				{showPrintDownload && (
					<div className='row gx-3 my-2 mb-3 justify-content-center justify-content-lg-between'>
                        <div className='col-12 col-sm-10 col-lg-3 order-2 order-lg-1'>
                            {canSendToVendor &&
                            <button disabled={isLoading} onClick={onCreateProfessionalPrintingDocument} className={`btn btn-outline-primary btn-icon w-100 mt-3 mt-lg-0 ${isLoading ? "btn-working" : ""}`}>
                                {getConfigValue(appState.config?.ProfessionalPrinting?.SendToPrint, appState)?.Value} <span className='fa-regular fa-paper-plane btn-icon'></span>
                            </button>
                            }
                        </div>
                        <div className='col-12 col-sm-10 col-lg-7 col-xl-6 order-1 order-lg-2'>
                            <div className='row g-0 justify-content-end'>
                                {canPrint &&
                                <div className='col-12 col-lg-6 pe-lg-3'>
                                    <button disabled={isLoading} onClick={onPrintClick} className={`btn btn-outline-primary btn-icon w-100 ${isLoading ? "btn-working" : ""}`}>
                                        {getConfigValue(appState.config.Preview?.Print, appState)?.Value} <span className='fa-regular fa-print btn-icon'></span>
                                    </button>                                
                                </div>
                                }
                                {canDownload &&
                                <div className='col-12 col-lg-6'>
                                    <button disabled={isLoading} onClick={onDownloadClick} className={`btn btn-outline-primary btn-icon w-100 mt-3 mt-lg-0 ${isLoading ? "btn-working" : ""}`}>
                                        {getConfigValue(appState.config.Preview?.Download, appState)?.Value} <span className='fa-regular fa-download btn-icon'></span>
                                    </button>
                                </div>
                                }
                            </div>
                        </div>
					</div>
				)}
				<hr className='my-4' />
				<div style={{ height: '100vh' }} id='pdf-div' className='full-window-div border border-gray-100 h-screen' />
			</Modal.Body>
		</Modal>
	);
}

export default PreviewModal;
