/*
Copyright 2020 Adobe
All Rights Reserved.

NOTICE: Adobe permits you to use, modify, and distribute this file in
accordance with the terms of the Adobe license agreement accompanying
it. If you have received this file from a source other than Adobe,
then your use, modification, or distribution of it requires the prior
written permission of Adobe.
*/

import { config } from "@fortawesome/fontawesome-svg-core";

class AdobePreview {
    constructor() {
        this.readyPromise = new Promise((resolve) => {
            if (window.AdobeDC) {
                resolve();
            } else {
                document.addEventListener("adobe_dc_view_sdk.ready", () => {
                    resolve();
                });
            }
        });
        this.adobeDCView = undefined;
    }
    ready() {
        return this.readyPromise;
    }
    previewFile(divId, viewerConfig, url, fileName) {
        const config = {
            clientId: `${process.env.REACT_APP_CLIENT_ID}`, ///enter client id here
        };
        if (divId) {
            config.divId = divId;
        }
        this.adobeDCView = new window.AdobeDC.View(config);
        const previewFilePromise = this.adobeDCView.previewFile(
            //Content URL and MetaData Information will be changed 
            {
                content: {
                    location: {
                        //Url from documentGenerate response will be passed into here. 
                        url: url,
                    },
                },
                metaData: {
                    //Filename from response back from generatePDF will be passed into here. 
                    fileName: fileName,
                },
            },
            viewerConfig
        );
        return previewFilePromise;
    }
    previewFileUsingFilePromise(divId, filePromise, fileName) {
        this.adobeDCView = new window.AdobeDC.View({
            clientId: `${process.env.REACT_APP_CLIENT_ID}`, //enter Client id here
            divId,
        });
        this.adobeDCView.previewFile(
            {
                content: {
                    promise: filePromise,
                },
                metaData: {
                    fileName: fileName,
                }, 
            },
            {
                showAnnotationTools: false,
                showLeftHandPanel: false,
                showPageControls: false,
                showDownloadPDF: false,
                showPrintPDF: false
            }
        );
    }
}
export default AdobePreview;